.phone-input {
  
  .PhoneInput {
    display: flex;

    .PhoneInputCountry {
      margin-right: 10px;
    }
  }
}
