h1 {
  font-size: 2.25rem;
  line-height: 2.75rem;
  font-weight: bold;
}

h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: bold;
}

h6 {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
}

