input, button, a {
  outline: none !important;
}

.filter-select {
  @apply min-w-25 h-12;

  .MuiSelect-select {
    height: 100% !important;
    padding: 0.5rem 3rem 0.5rem 1.25rem !important;
    border: 1px solid #CED8E3 !important;
    border-radius: 100px !important;
    box-sizing: border-box;
    line-height: 1.5 !important;
    @apply flex items-center bg-white;
    @apply text-blue font-semibold transition-all;

    &[aria-expanded=true] {
      color: white !important;
      background: #44586D !important;
      border-color: #44586D !important;

      & ~ .MuiSelect-icon {
        @apply text-white;
      }
    }
  }

  .MuiSelect-icon {
    @apply text-blue right-4 transition-all;
  }
}

.filter-select-menu {

  .MuiMenu-paper {
    background: white !important;
    box-shadow: 0 2px 32px rgba(2, 47, 101, 0.12) !important;
    border-radius: 8px !important;
    margin-top: 4px !important;

    .MuiMenuItem-root {
      padding: 10px 16px !important;

      &.p-0 {
        padding: 0 !important;
      }
    }
  }
}

.MuiCheckbox-root {
  color: #CED8E3 !important;

  .MuiSvgIcon-root {
    @apply w-7 h-7;
  }

  &.MuiCheckbox-colorPrimary {

    &.Mui-checked {
      color: #193451 !important;
    }
  }
}

.icon-btn {
  @apply w-12 h-12 flex items-center justify-center shrink-0;
  @apply bg-white text-blue border border-secondary rounded-full;
  @apply hover:text-white hover:bg-blue-800 cursor-pointer transition-all;

  &.btn-sm {
    @apply w-10 h-10;
  }

  &.active {
    @apply text-white bg-blue-800;
  }
}

.btn {
  @apply h-12 flex items-center justify-center font-semibold rounded-3xl px-6 py-3;
  @apply transition-all;

  &.btn-blue {
    @apply text-white bg-blue;

    &:not(:disabled) {
      @apply hover:bg-blue-900;
    }
  }

  &:disabled {
    @apply opacity-60;
  }
}

.form-field {
  @apply flex flex-col;

  .MuiInputBase-root {
    @apply bg-blue-100 border border-secondary rounded transition-all;

    &.Mui-focused {
      @apply border-blue-800;
    }

    &.Mui-error {
      border-color: #d32f2f !important;
    }
  }

  input, textarea {
    @apply w-full font-normal text-base text-blue-900 rounded;
    @apply px-5 py-3 transition-all;
    outline: none !important;
  }

  .invalid-feedback {
    @apply hidden text-xs mt-1 ml-1;
  }

  &.full-width {
    @apply w-full;
  }

  &.is-invalid {
    @apply text-danger;

    input {
      @apply text-blue-900 border-danger;
    }

    .invalid-feedback {
      @apply block;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    @apply hidden;
  }
}
