.country-select {
  position: relative;
  width: 100%;
  display: flex !important;
  align-items: center;
  outline: none;

  .PhoneInput {
    width: 100%;
    height: 100%;

    .PhoneInputCountry {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 4px 8px;

      .PhoneInputCountryIcon {
        box-shadow: none !important;
      }

      .PhoneInputCountrySelectArrow {
        display: none;
      }
    }

    .PhoneInputInput {
      display: none;
    }
  }

  .country-name {
    position: absolute;
    left: 60px;
    pointer-events: none;
  }
}
