.region-code-select {
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;

  .PhoneInput {
    width: 100%;
    height: 100%;

    .PhoneInputCountry {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 4px 8px;

      .PhoneInputCountryIcon {
        box-shadow: none !important;
      }

      .PhoneInputCountrySelectArrow {
        display: none;
      }
    }

    .PhoneInputInput {
      display: none;
    }
  }

  .region-code {
    position: absolute;
    left: 40px;
    pointer-events: none;
  }
}
