.datatable-wrapper {

  .datatable {
    @apply grid;
    @apply text-blue-900;

    .th, .td, .tf {
      @apply flex;
    }

    .th {
      @apply items-center;
    }
  }

  &.table-md {

    .datatable {

      .th {
        @apply font-semibold px-6 py-4;
      }

      .td {
        @apply p-6;
      }

      .group-row {
        @apply flex items-center bg-blue-200 rounded-t px-6 py-4;

        &:not(:first-child) {
          @apply mt-2;
        }

        .title {
          @apply font-semibold;
        }

        .badge {
          @apply text-blue-400 ml-2;
        }
      }
    }
  }

  &.theme-default {

    .datatable {

      .td, .tf {
        @apply border-t border-blue-200;
      }
    }
  }

  &.theme-card-row {

    .datatable {

      .td {
        @apply bg-white;
        margin-bottom: 2px;

        &.td-first {
          @apply rounded-l;
        }

        &.td-last {
          @apply rounded-r;
        }
      }
    }
  }
}
